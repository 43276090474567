export type ReplyResponseDto =
    {
        id: string,
        date: string,
        status: Status,
        fullName: string | null,
        contact: string | null,
        address: string | null,
        messenger: string | null,
        message: string,
        appeal_type: Type,
        citizen_id: number,
    };

export enum Status {
    waiting = "WAITING",
    inWork = "INWORK",
    closed = "CLOSED",
}

export enum Type {
    others = "OTHERS",
    complaint = "COMPLAINT",
}